/* navbar */
.navbar {
  height: 80px;
  background-color: #f7f7f7 !important;
  z-index: 10000 !important;
}

.navbar-nav {
  margin-left: auto !important;
}

.navbar .nav-link {
  color: #242424;
  font-size: 1.3rem;
}

.navbar .nav-link.active {
  color: #ed7117;
}

.navbar .nav-link:hover {
  color: #ed7117;
}

.menu-icon {
  display: none;
}

.dropdown-menu {
  background: #f7f7f7;
}

@media screen and (max-width: 960px) {
  .logo-img {
    width: 90%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: -4px;
    right: -7px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #ed7117;
  }

  .fa-times {
    color: #ed7117;
    font-size: 2rem;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-link.active {
    color: #242424 !important;
  }

  .navbar-nav.active {
    background-color: #f7f7f7;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-link {
    text-align: center;
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-link:hover {
    background-color: #f79652;
    color: #242424 !important;
    border: 0;
  }

  .hide-on-mobile {
    display: none;
  }
}
