.title {
  position: absolute;
  left: 34px;
  top: 40px;
  text-align: center;
  font-size: 20px;
}

.price {
  position: absolute;
  left: 35px;
  top: 85px;
  font-weight: normal;
  text-align: center;
  margin-top: 30px;
  font-size: 18px;
}

.btn-buy {
  padding: 8px 40px;
  cursor: pointer;
  background-color: #f7f7f7;
  border: 1px solid #fff;
  color: #ed7117;
  text-transform: uppercase;
  position: absolute;
  top: 125px;
  left: 55px;
  margin-top: 25px;
  font-weight: 500;
}

.btn-buy:hover {
  /* background-color: #f7f7f7;
  background: transparent;
  color: #fff; */
  transform: scale(1.2);
}
@media (min-width: 800px) {
  .display-desktop {
    display: block;
  }

  .hide-from-desktop {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .img {
    width: 100% !important;
  }
  .mobile-btn-buy {
    padding: 10px 40px;
    cursor: pointer;
    background-color: #f7f7f7;
    border: 1px solid #ed7117;
    color: #ed7117;
    text-transform: uppercase;
    width: 83.5%;
  }

  .mobile-btn-buy:visited,
  .mobile-btn-buy:focus {
    color: #ed7117 !important;
  }

  .mobile-btn-buy:hover {
    transform: scale(1.2);
  }
  .title-mobile {
    font-size: 19.1px !important;
  }

  .display-mobile {
    display: block;
  }

  .hide-mobile {
    display: none;
  }
  .col-md-4 {
    width: 100%;
  }
  .box {
    width: 100%;
    min-height: 680px;
    background: #ffffff;
    box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.09);
  }

  .plan {
    width: 100%;
    min-height: 680px;
    background: #ffffff;
    color: #000 !important;
    box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.09);
    transition: all 0.7s ease-in-out;
    cursor: pointer;
  }

  .plan h4 {
    color: #ed7117 !important;
    font-size: 1.5rem;
  }

  .plan:hover {
    /* background: #ED7117; */
    color: black;
    border-radius: 10px;
    transform: translateY(-5px);
    box-shadow: 0 21px 19px -2px rgba(217, 217, 217, 1);
  }

  .plan:hover h4 {
    color: #000 !important;
  }

  .plan:hover p {
    color: #000 !important;
  }

  .plan:hover ul.list-group {
    background-color: transparent !important;
  }

  .my-flex-column{
    flex-direction: column !important;
  }
}
