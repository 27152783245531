.input {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
  border-radius: 0;
  padding: 15px;
  font-size: 16px;
}

.input:focus {
  outline: #ed7117 !important;
  border: 1px solid #ed7117 !important;
  font-size: 16px;
}

.btn-auth {
  width: 100%;
  background: #ed7117;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 0;
  padding: 15px;
}

.textarea {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
  border-radius: 0;
  min-height: 200px;
  padding: 15px;
}

textarea:focus {
  outline: #ed7117 !important;
  border: 1px solid #ed7117 !important;
}
