.features .circle {
    position: relative;
    top: 0;
    left: 30px;
    width: 70px;
    height: 70px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px 1px #E1EEFC;
    border-radius: 50%;
}

@media screen and (max-width : 768px) {
    .features .circle{
        left: 0;
        margin-bottom: 10px !important;
    }
}

