.btn {
    cursor: pointer;
    outline: none;
    border: none;
    text-transform: uppercase;
}

.btn:focus {
    outline: none;
    box-shadow: none;
}

.btn-my-primary {
    background-color: #ED7117;
    color: #fff;
    border: 1px solid #ED7117 !important;
}

.btn-outline {
    background-color: transparent;
    color: #ED7117;
    padding: 8px 20px;
    border: 1px solid #ED7117 !important;
    transition: all .3s ease-out;
}

.btn-md {
    padding: 6px 15px;
    font-size: 20px;
}

.btn-lg {
    padding: 8px 25px;
    font-size: 20px;
}

.btn-md:hover,
.btn-lg:hover,
.btn-mobile:hover {
    background: transparent;
    color: #ED7117 !important;
    transition: all .3s ease-out;
}

.btn-mobile {
    text-align: center;
    border-radius: 4px;
    width: 100%;
    text-decoration: none;
    font-size: 24px;
    background-color: transparent;
    padding: 14px 20px;
    transition: all 0.3s ease-out;
    color: #fff;
    border: none;
    background-color: #ED7117;
}
