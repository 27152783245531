.circle {
  width: 65px;
  height: 65px;
  background: #ffffff;
  box-shadow: 0px 4px 4px 1px #e1eefc;
  border-radius: 50%;
}

.circle img {
  margin-top: 20%;
}

.feature {
  min-width: 390px;
  min-height: 300px;
  background: #ffffff;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.02);
}

.feature:hover {
  color: #fff;
  background: #ed7117;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: 0px;
}

.feature:hover p {
  color: #fff !important;
}

.circle-2 {
  position: relative;
  top: 0;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background: #ed7117;
}

.circle-2 img {
  position: absolute;
  top: 15px;
  left: 20px;
  right: 20px;
}

@media screen and (max-width: 480px) {
  .circle {
    margin-bottom: 20px;
  }

  .feature {
    min-width: 300px;
  }
}
