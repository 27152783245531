/* Global styles */

/* custom scroll bar */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 7px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #ed7117;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ed7117;
}

ul {
  list-style: none;
}

.react-chatbot-kit-chat-input {
  outline: none !important;
}

a,
a:visited,
.link {
  text-decoration: none;
  color: inherit;
}

body {
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  color: #242424;
}

.img-mobile {
  width: 100% !important;
}

.line::before {
  content: "";
  width: 75px;
  height: 3px;
  display: block;
  margin: 0 auto;
  background-color: #ed7117;
}

.my-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.rec.rec-arrow {
  background: #fff !important;
  color: #ed7117 !important;
  border-radius: 0;
}

.rec.rec-arrow:hover {
  border-radius: 50%;
  background-color: #ed7117 !important;
  color: #fff !important;
}

.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px lightgrey;
}

.jLgnwX,
.ejXxNI {
  background-color: #ed7117 !important;
  box-shadow: 0 0 1px 3px rgb(224, 108, 25);
}

button.rec-dot {
  background-color: #ed7117;
  box-shadow: 0 0 1px 3px #ed7117;
}

button.rec-dot:hover,
button.rec-dot:active,
button.rec-dot:focus {
  box-shadow: 0 0 1px 3px #e6b18c;
}

/* customers testimonial*/
.card {
  border: none;
  box-shadow: 0 1px 2px 0 rgba(90, 91, 95, 0.1);
  transition: transform 300ms ease-in-out;
}

.card:hover {
  box-shadow: 0 21px 19px -2px #d9d9d9;
  transform: translateY(-5px) !important;
}

.user-content p {
  margin-top: 5px;
  font-size: 12px;
}

.ratings i {
  color: #ed7117;
}

/* FAQ */

.accordion .accordion-item button {
  color: #ed7117;
  box-shadow: none;
}

.accordion .accordion-item button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

@media (min-width: 575px) and (max-width: 960px) {
  .my-flex-column {
    flex-direction: column;
  }

  .hero .intro {
    text-align: center !important;
    margin-bottom: 30px;
  }

  /* what diff us */
  .us .col-md-6 {
    width: 100%;
  }

  .features .col-md-8 {
    width: 100% !important;
    text-align: center !important;
  }

  .us p {
    text-align: center;
  }

  /* onlogis features */

  .full-w {
    width: 100% !important;
  }

  /* we are close to you  */
  .cty .col-md-4 {
    width: 100%;
  }

  .width-50 {
    width: 50% !important;
  }

  .contact-us .col-md-6 {
    width: 100%;
  }

  .contact-us .col-md-5 {
    width: 100%;
  }

  .my-flex-column {
    flex-direction: column !important;
  }
}

/* Scroll button */
.scroll-btn {
  position: fixed;
  right: 20px;
  bottom: 45px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: #ff8a00;
}

@media screen and (max-width: 960px) {
  .scroll-btn {
    right: 15px !important;
    bottom: 48px;
    right: 155px !important;
  }
}

/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  left: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
  color: #fff !important;
}

.btn-chatbot {
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ed7117;
  color: #fff;
  outline: none;
  border: none;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  cursor: pointer;
}
/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon,
  .chatbot-icon {
    font-size: 1.8rem;
    margin-top: 11px;
  }
  .whatsapp_float {
    width: 50px;
    height: 50px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
  .chatbot {
    width: 50px;
    height: 50px;
    font-size: 22px;
    right: 15px;
    bottom: 20px;
  }
  .btn-chatbot {
    width: 50px;
    height: 50px;
    font-size: 22px;
    right: 15px;
    bottom: 20px;
    z-index: 10000;
  }
  .btn-chatbot i {
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff !important;
  }
}

@media screen and (max-width: 1024px) {
  .full-w {
    width: 100% !important;
  }
  .width-50 {
    width: 50% !important;
  }
}

.react-chatbot-kit-chat-inner-container {
  position: fixed;
  right: 80px !important;
  top: 14vh;
  z-index: 10000;
  width: 25% !important;
  height: 80vh !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.react-chatbot-kit-chat-header,
.react-chatbot-kit-chat-btn-send {
  background-color: #ed7117 !important;
  color: #fff !important;
}

.react-chatbot-kit-chat-bot-message {
  background-color: #02458c !important;
  width: 100% !important;
}

.react-chatbot-kit-chat-bot-message-arrow {
  border-right: 8px solid #02458c !important;
}

.react-chatbot-kit-chat-bot-message {
  margin-left: 0px !important;
}
.react-chatbot-kit-chat-input,
.react-chatbot-kit-chat-input:focus {
  font-size: 16px;
}

@media screen and (max-width: 960px) {
  .react-chatbot-kit-chat-inner-container {
    z-index: 10000;
    top: 5.5rem !important;
    left: 0rem !important;
    height: 66.3vh !important;
    width: 100% !important;
    padding: 2px !important;
  }
  .btn-chatbot i {
    color: #fff !important;
  }
}

@media screen and (min-width: 1300px) {
  .react-chatbot-kit-chat-inner-container {
    height: 70vh !important;
  }
}
