.options-container {
  display: flex;
  flex-wrap: wrap;
}

.option-button {
  margin: 5px;
  border-radius: 10px;
  padding: 8px;
  border-color: #ed7117;
  background: transparent;
  text-align: center;
  outline: none;
  cursor: pointer;
}
